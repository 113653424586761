import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSmoothScroll from 'vue2-smooth-scroll'
import VueCarousel from 'vue-carousel'
// import VModal from 'vue-js-modal'
import './assets/css/tailwind.css'

Vue.config.productionTip = false

Vue.use(VueCarousel)
// Vue.use(VModal)
Vue.use(VueSmoothScroll, {
	duration: 1000,
	updateHistory: false,
	// easingFunction: t => (--t)*t*t+1,
})

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
