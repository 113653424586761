<template>
	<form @submit.prevent="submit">
		<div class="space-y-4 text-left text-black">

			<div class="grid gap-x-8 gap-y-4">
				<div class="relative">
					<input id="nmllc-name" name="name" type="text" :class="errors && errors.name ? 'border-red-500' : 'border-gray-400'" placeholder="First Name"
						v-model="fields.name"
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteOrange focus-visible:border-siteOrange"
					>
					<label for="nmllc-name"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.name ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-300 peer-focus:text-black'"
					>First Name</label>
					<span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.name ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span>
					<InputError :field="errors.name" class="mt-1 Xmb-[10px] text-xs text-red-500"/>
				</div>

				<div class="relative">
					<input id="nmllc-contact" name="contact" type="tel" :class="errors && errors.contact ? 'border-red-500' : 'border-gray-400'" placeholder="Contact"
						v-model="fields.contact"
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteOrange focus-visible:border-siteOrange"
					>
					<label for="nmllc-contact"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.contact ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-300 peer-focus:text-black'"
					>Contact</label>
					<span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.contact ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span>
					<InputError :field="errors.contact" class="mt-1 Xmb-[10px] text-xs text-red-500"/>
				</div>

				<div class="relative">
					<input id="nmllc-email" name="email" type="email" :class="errors && errors.email ? 'border-red-500' : 'border-gray-400'" placeholder="Email"
						v-model="fields.email"
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteOrange focus-visible:border-siteOrange"
					>
					<label for="nmllc-email"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.email ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-300 peer-focus:text-black'"
					>Email</label>
					<span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.email ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span>
					<InputError :field="errors.email" class="mt-1 Xmb-[10px] text-xs text-red-500"/>
				</div>

				<div class="relative">
					<textarea rows="6" id="nmllc-message" name="message" type="tel" :class="errors && errors.message ? 'border-red-500' : 'border-gray-400'" placeholder="Message"
						v-model="fields.message"
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteOrange focus-visible:border-siteOrange"
					/>
					<label for="nmllc-message"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.message ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-300 peer-focus:text-black'"
					>Message</label>
					<!-- <span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.message ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span> -->
					<InputError :field="errors.message" class="mt-1 Xmb-[10px] text-xs text-red-500"/>
				</div>

			</div>

			<div class="text-center">
				<button type="submit" v-bind:disabled="busy"
					class="flex justify-center w-full p-4 text-white uppercase bg-[#0074C8] rounded-[4px] duration-200 hover:bg-blue-600 focus:outline-none">
					<span class="px-2">
						Submit
						<svg class="inline-block w-6 h-6" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" v-if="busy">
							<defs>
								<linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
									<stop stop-color="#fff" stop-opacity="0" offset="0%"/>
									<stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/>
									<stop stop-color="#fff" offset="100%"/>
								</linearGradient>
							</defs>
							<g fill="none" fill-rule="evenodd">
								<g transform="translate(1 1)">
									<path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
										<animateTransform
											attributeName="transform"
											type="rotate"
											from="0 18 18"
											to="360 18 18"
											dur="0.9s"
											repeatCount="indefinite" />
									</path>
									<circle fill="#fff" cx="36" cy="18" r="1">
										<animateTransform
											attributeName="transform"
											type="rotate"
											from="0 18 18"
											to="360 18 18"
											dur="0.9s"
											repeatCount="indefinite" />
									</circle>
								</g>
							</g>
						</svg>
					</span>
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import hapiMixins from '@juno0424/vue2-hapi';
import {InputError} from '@juno0424/vue2-hapi-inputs';

export default {
	mixins: [hapiMixins],
	components: {InputError},
	name: 'ContactForm',
	data() {
		return {
			name: 'ContactForm',
			endpoint: "https://hapiform.sg/api/ed19a0a5-0950-4543-906e-8ae7a0a484a0",
			redirectTo: "/thank-you/",
			fields: {branch: ''},
		};
	}
}
</script>
